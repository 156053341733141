import { makeVar } from '@apollo/client';

interface User {
  email?: string | null;
  password?: string | null;
  passwordConfirmation?: string | null;
  firstName: string | null;
  preferredName: string | null;
  lastName: string | null;
}

interface UserSignup {
  authToken: string;
  email: string;
  firstName: string;
  preferredName: string;
  lastName: string;
  intercomUserHash: string;
  uuid: string;
  gclid: string;
  signupSource: string | undefined;
  signupSourceQualifier: string | undefined;
}

interface NurseProfile {
  nurseType: string | null;
  roleId: string | null;
}

export interface PrimarySignupFormValues {
  userSignup: UserSignup;
  user: User;
  nurseProfile: NurseProfile;
}

// Represents the data necessary for the initial steps of the signup flow, before the user is created
export const primarySignupFormVar = makeVar<PrimarySignupFormValues>({
  user: {
    email: null,
    password: null,
    passwordConfirmation: null,
    firstName: null,
    preferredName: null,
    lastName: null,
  },
  userSignup: {
    authToken: '',
    email: '',
    firstName: '',
    preferredName: '',
    lastName: '',
    intercomUserHash: '',
    uuid: '',
    gclid: '',
    signupSource: '',
    signupSourceQualifier: '',
  },
  nurseProfile: {
    nurseType: null,
    roleId: null,
  },
});
