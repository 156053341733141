import Image from 'next/image';
import type { PropsWithChildren } from 'react';

import { SnackbarContainer } from '@src/components/snackbar-container';
import { TrustedHead } from '@src/components/trusted-head';
import { DESKTOP_MIN_WIDTH } from '@src/constants/breakpoints';
import * as colors from '@src/support/colors';
import { Gap, Title } from '@src/ui';

type Props = {
  title: string;
};

export function AuthLayout(props: PropsWithChildren<Props>) {
  return (
    <div className="AuthLayout">
      <TrustedHead />
      {/* Do not change this page title. Prevents us from indexing well on public job search results. */}
      <SnackbarContainer />
      <div className="AuthLayoutBackground">
        <div className="AuthLayoutLogo">
          <Image
            src={'/static/trusted_wordmark_black.svg'}
            alt={'Trusted'}
            width={400}
            height={80}
          />
        </div>
      </div>
      <div className="AuthLayoutForeground">
        <div className="AuthLayoutForegroundContent">
          <Gap size="large">
            <Title size="h1" weight="semiBold">
              {props.title}
            </Title>
            {props.children}
          </Gap>
        </div>
      </div>
      <style jsx>{`
        .AuthLayout {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .AuthLayoutBackground {
          align-items: center;
          background-color: ${colors.white};
          display: flex;
          justify-content: center;
          padding: 2.5rem;
        }

        .AuthLayoutLogo {
          max-width: 13.25rem;
        }

        .AuthLayoutForeground {
          background-color: ${colors.white};
          padding: 1.25rem;
          display: flex;
        }

        .AuthLayoutForegroundContent {
          flex-grow: 1;
        }

        .AuthLayoutForegroundContent > h1 {
          font-size: 2rem;
          font-weight: bold;
        }

        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
          .AuthLayout {
            flex-direction: row-reverse;
          }

          .AuthLayoutBackground {
            background-color: ${colors.black};
            flex-grow: 1;
            padding: 2.5rem;
          }

          .AuthLayoutForeground {
            padding: 2.5rem;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
          }

          .AuthLayoutLogo {
            filter: invert(1) brightness(10);
          }

          .AuthLayoutForegroundContent {
            max-width: 31.25rem;
          }
        }

        @media screen and (min-width: 64rem) {
          .AuthLayoutLogo {
            max-width: 18.5rem;
          }

          .AuthLayoutForeground {
            padding: 5rem;
          }
        }
      `}</style>
    </div>
  );
}
